<template>
    <div :style="`width: ${width}px`" class="mx-auto">
        <div class="d-flex align-center">
            <h2>SCORE: {{ score }}</h2>
            <v-chip
                label
                :class="`${scoreMeta.color} ${scoreMeta.fontClass} font-weight-bold text-uppercase ml-2`"
                >{{ scoreMeta.label }}</v-chip
            >
        </div>
        <div :style="`width: ${scorePosition}px`" class="text-right">
            <v-icon light large :color="scoreMeta.color"
                >mdi-chevron-down-circle</v-icon
            >
        </div>
        <div class="d-flex">
            <template v-for="(item, index) in progressItems">
                <div
                    :style="`width: ${item.width}px`"
                    :key="index"
                    :class="item.itemClass"
                >
                    <v-progress-linear
                        :height="height"
                        :color="item.color"
                        value="100"
                        :rounded="item.rounded"
                        :class="item.fontClass"
                    >
                        {{ item.label }}</v-progress-linear
                    >
                </div>
            </template>
        </div>
        <div class="d-flex" style="margin-top: 1px">
            <template v-for="(item, index) in progressItems">
                <div
                    :style="`width: ${item.width}px`"
                    :key="`label-${index}`"
                    class="d-flex"
                    :class="item.itemClass"
                >
                    <v-chip
                        small
                        label
                        color="grey lighten-2"
                        class="grey--text text--darken-3 font-weight-bold"
                        >{{ item.min }}</v-chip
                    >
                    <v-spacer v-if="index === items.length - 1"></v-spacer>

                    <v-chip
                        small
                        label
                        color="grey lighten-2"
                        class="grey--text text--darken-3 font-weight-bold"
                        v-if="index === items.length - 1"
                        >{{ item.max }}</v-chip
                    >
                </div>
            </template>
        </div>
    </div>
</template>
<script>
const SCORE_RANGE = 500;
export default {
    name: "credit-score",
    components: {},
    props: {
        score: {
            type: Number,
            required: true,
        },
        width: {
            type: Number,
            required: false,
            default: 800,
        },
        height: {
            type: Number,
            required: false,
            default: 30,
        },
    },
    data() {
        return {
            items: [
                {
                    label: "Poor",
                    rounded: true,
                    itemClass: "",
                    min: 350,
                    max: 520,
                    color: "error",
                    fontClass: "white--text",
                },
                {
                    label: "Fair",
                    rounded: false,
                    itemClass: "ml-n1",
                    min: 520,
                    max: 640,
                    color: "warning",
                    fontClass: "",
                },
                {
                    label: "Good",
                    rounded: false,
                    itemClass: "ml-n1 mr-n1",
                    min: 640,
                    max: 720,
                    color: "secondary lighten-2",
                    fontClass: "white--text",
                },
                {
                    label: "Excellent",
                    rounded: true,
                    itemClass: "",
                    min: 720,
                    max: 850,
                    color: "secondary darken-1",
                    fontClass: "white--text",
                },
            ],
        };
    },
    computed: {
        scoreMeta() {
            const { color, fontClass, label } = this.items.find(
                (item) => this.score >= item.min && this.score < item.max
            );
            return { color, fontClass, label };
        },
        scorePosition() {
            const pxPerValue = this.width / SCORE_RANGE;
            return Math.round((this.score - 350) * pxPerValue) + 8;
        },
        progressItems() {
            const pxPerValue = this.width / SCORE_RANGE;
            return this.items.map((item) => {
                item.width = Math.round((item.max - item.min) * pxPerValue);
                return item;
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>