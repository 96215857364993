var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto", style: "width: " + _vm.width + "px" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("h2", [_vm._v("SCORE: " + _vm._s(_vm.score))]),
          _c(
            "v-chip",
            {
              class:
                _vm.scoreMeta.color +
                " " +
                _vm.scoreMeta.fontClass +
                " font-weight-bold text-uppercase ml-2",
              attrs: { label: "" }
            },
            [_vm._v(_vm._s(_vm.scoreMeta.label))]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "text-right",
          style: "width: " + _vm.scorePosition + "px"
        },
        [
          _c(
            "v-icon",
            { attrs: { light: "", large: "", color: _vm.scoreMeta.color } },
            [_vm._v("mdi-chevron-down-circle")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm._l(_vm.progressItems, function(item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  class: item.itemClass,
                  style: "width: " + item.width + "px"
                },
                [
                  _c(
                    "v-progress-linear",
                    {
                      class: item.fontClass,
                      attrs: {
                        height: _vm.height,
                        color: item.color,
                        value: "100",
                        rounded: item.rounded
                      }
                    },
                    [_vm._v(" " + _vm._s(item.label))]
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "d-flex", staticStyle: { "margin-top": "1px" } },
        [
          _vm._l(_vm.progressItems, function(item, index) {
            return [
              _c(
                "div",
                {
                  key: "label-" + index,
                  staticClass: "d-flex",
                  class: item.itemClass,
                  style: "width: " + item.width + "px"
                },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "grey--text text--darken-3 font-weight-bold",
                      attrs: { small: "", label: "", color: "grey lighten-2" }
                    },
                    [_vm._v(_vm._s(item.min))]
                  ),
                  index === _vm.items.length - 1 ? _c("v-spacer") : _vm._e(),
                  index === _vm.items.length - 1
                    ? _c(
                        "v-chip",
                        {
                          staticClass:
                            "grey--text text--darken-3 font-weight-bold",
                          attrs: {
                            small: "",
                            label: "",
                            color: "grey lighten-2"
                          }
                        },
                        [_vm._v(_vm._s(item.max))]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }